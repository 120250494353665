.background {
    height: 100vh;
    background-image: linear-gradient(#7DABC0, #D5C8B9);
}

.instagram-icon {
    margin-left: 0.5em;
}

.header {
    margin: 7em 2em;
}

.h1 {
    display: inline;
    font-weight: bold;
    color: white;
}

.description {
    display: inline;
    color: white;
}

.links {
    margin: 1em 0;
}

.note {
    margin-top: 4em;
    color: white;
}
